import React from 'react';

import SEO from 'components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Deze pagina bestaat niet</h1>
    <p>Helaas bestaat deze pagina niet.</p>
  </>
);

export default NotFoundPage;
